<script>
// Inspiration :: https://stackoverflow.com/questions/60928670/vue-chartjs-doughnut-chart-with-text-in-the-middle
import { Line } from "vue-chartjs";
// import Chart from "chart.js";

export default {
  name: "LineChart",
  extends: Line,
  props: {
    showData: { type: Object, required: false, default: () => null },
  },
  mounted() {
    this.renderChart(this.showData, {
      legend: {
        display: false,
      },
      animation: {
        animateScale: true,
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              unit: "day",
            },
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: false,
    });
  },
};
</script>

<style scoped></style>
